import React from "react";
import Container from "react-bootstrap/Container";

export default function AboutPage() {
  return (
    <Container className="p-3" fluid>
      About
    </Container>
  );
}

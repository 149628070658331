import React from "react";
import Container from "react-bootstrap/Container";

export default function SettingsPage() {
  return (
    <Container className="p-3" fluid>
      Settings
    </Container>
  );
}

/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_appsync_graphqlEndpoint": "https://fu45p5slyfhilbzw3xscoyue6i.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-3brm4dzr4bae7akudyehljx3ly",
    "aws_cognito_identity_pool_id": "us-east-1:29622c67-771c-41ec-8dc0-2e0e5ad8eb44",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_y0oxhlKxA",
    "aws_user_pools_web_client_id": "5t8aeb0mu7al8e0e7r8i9jf997",
    "oauth": {
        "domain": "propertypro268610430-68610430-prod.auth.us-east-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "http://localhost:3000/,https://master.d24fj1jxstwr6o.amplifyapp.com/,https://www.propertypro.services/",
        "redirectSignOut": "http://localhost:3000/,https://master.d24fj1jxstwr6o.amplifyapp.com/,https://www.propertypro.services/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [
        "GOOGLE"
    ],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ]
};


export default awsmobile;

/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createSession = /* GraphQL */ `
  mutation CreateSession(
    $input: CreateSessionInput!
    $condition: ModelSessionConditionInput
  ) {
    createSession(input: $input, condition: $condition) {
      id
      owner
      name
      searchCity
      searchBounds {
        bottomLeft {
          lat
          lng
        }
        topRight {
          lat
          lng
        }
      }
      listings {
        id
        name
        address
        location {
          lat
          lng
        }
        price
        squareFootage
        numberOfBedrooms
        numberOfBathrooms
        link
        notes
        pictures
        status
      }
      pointsOfInterest {
        id
        name
        type
        address
        location {
          lat
          lng
        }
      }
      roommates
      createdAt
      updatedAt
    }
  }
`;
export const updateSession = /* GraphQL */ `
  mutation UpdateSession(
    $input: UpdateSessionInput!
    $condition: ModelSessionConditionInput
  ) {
    updateSession(input: $input, condition: $condition) {
      id
      owner
      name
      searchCity
      searchBounds {
        bottomLeft {
          lat
          lng
        }
        topRight {
          lat
          lng
        }
      }
      listings {
        id
        name
        address
        location {
          lat
          lng
        }
        price
        squareFootage
        numberOfBedrooms
        numberOfBathrooms
        link
        notes
        pictures
        status
      }
      pointsOfInterest {
        id
        name
        type
        address
        location {
          lat
          lng
        }
      }
      roommates
      createdAt
      updatedAt
    }
  }
`;
export const deleteSession = /* GraphQL */ `
  mutation DeleteSession(
    $input: DeleteSessionInput!
    $condition: ModelSessionConditionInput
  ) {
    deleteSession(input: $input, condition: $condition) {
      id
      owner
      name
      searchCity
      searchBounds {
        bottomLeft {
          lat
          lng
        }
        topRight {
          lat
          lng
        }
      }
      listings {
        id
        name
        address
        location {
          lat
          lng
        }
        price
        squareFootage
        numberOfBedrooms
        numberOfBathrooms
        link
        notes
        pictures
        status
      }
      pointsOfInterest {
        id
        name
        type
        address
        location {
          lat
          lng
        }
      }
      roommates
      createdAt
      updatedAt
    }
  }
`;

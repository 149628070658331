import Amplify, { Auth } from "aws-amplify";
import config from "./aws-exports";

export default function Initialize() {
  const isLocalhost = Boolean(
    window.location.hostname === "localhost" ||
      // [::1] is the IPv6 localhost address.
      window.location.hostname === "[::1]" ||
      // 127.0.0.1/8 is considered localhost for IPv4.
      window.location.hostname.match(
        /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
      )
  );

  // Assuming you have two redirect URIs, and the first is for localhost and second is for production
  const [
    localRedirectSignIn,
    autoGeneratedRedirectSignIn,
    productionRedirectSignIn,
  ] = config.oauth.redirectSignIn.split(",");

  const [
    localRedirectSignOut,
    autoGeneratedRedirectSignOut,
    productionRedirectSignOut,
  ] = config.oauth.redirectSignOut.split(",");

  const updatedAwsConfig = {
    ...config,
    oauth: {
      ...config.oauth,
      redirectSignIn: isLocalhost
        ? localRedirectSignIn
        : productionRedirectSignIn,
      redirectSignOut: isLocalhost
        ? localRedirectSignOut
        : productionRedirectSignOut,
    },
  };

  Amplify.configure({
    ...updatedAwsConfig,
    graphql_headers: async () => {
      try {
        const token = (await Auth.currentSession()).getIdToken().getJwtToken();
        return { Authorization: token };
      } catch (e) {
        console.error(e);
        return {};
      }
    },
  });
}
